.m0 {
  margin: 0;
}
.m1 {
  margin: 1rem;
}
.m2 {
  margin: 2rem;
}
.mtop0 {
  margin-top: 0;
}
.mbottom0 {
  margin-bottom: 0;
}
.mtop1 {
  margin-top: 1rem;
}
.mbottom1 {
  margin-bottom: 1rem;
}
.mtop2 {
  margin-top: 2rem;
}
.mbottom2 {
  margin-bottom: 2rem;
}
.p0 {
  padding: 0;
}
.p1 {
  padding: 1rem;
}
.p2 {
  padding: 2rem;
}
.ptop0 {
  padding-top: 0;
}
.pbottom0 {
  padding-bottom: 0;
}
.ptop1 {
  padding-top: 1rem;
}
.pbottom1 {
  padding-bottom: 1rem;
}
.pleft1 {
  padding-left: 1rem;
}
.ptop2 {
  padding-top: 2rem;
}
.pbottom2 {
  padding-bottom: 2rem;
}
.pleft2 {
  padding-left: 2rem;
}
.center {
  text-align: center;
}
.b {
  font-weight: 700;
}

.flex {
  display: flex;
}

.gap1 {
  gap: 1rem;
}

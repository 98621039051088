*,
*:before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat";
}

button,
input,
textarea,
select {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
}

img.site-banner {
  width: revert;
}

.xl {
  font-size: 4rem;
}
.l {
  font-size: 3rem;
}
.m {
  font-size: 2rem;
}
.s {
  font-size: 1.65rem;
}
.xs {
  font-size: 1.2rem;
}
